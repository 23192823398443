<template>
    <div class="my-goal">
        <div class="my-goal-content">
            <div class="goal-top">
                <span class="goal-top-title">我的交付目标</span>
                <!-- <el-button
                    class="hold-goal"
                    type="primary"
                    @click="holdGoal"
                    icon="el-icon-upload2">提交交付目标</el-button> -->
                <el-button
                    class="hold-goal"
                    type="primary"
                    @click="holdGoal">提交交付目标</el-button>
            </div>
            <div class="goal-list-wrap" ref="goalList" v-if="list.length>0">
                <div class="goal-list" v-for="(item, index) in list" :key="'mygoal'+ index">
                    <div class="cell-l">
                        <span class="empty"></span>
                        <span class="break-wrap">
                            <span class="date" v-text="item.monthly">2021-04</span>
                        </span>
                    </div>
                    <div class="cell-m">
                        <div class="cell-item">
                            <span class="goal-text">月推荐目标：
                                <i class="green" v-text="item.goalOfRecommandation">100</i>
                            </span>
                        </div>
                        <div class="cell-item">
                            <span class="goal-text">实际月推荐：
                                <i class="green" v-if="item.realOfRecommandation >= item.goalOfRecommandation" v-text="item.realOfRecommandation">100</i>
                                <i class="orange" v-else v-text="item.realOfRecommandation">100</i>
                            </span>
                            <span class="pop get" v-if="item.realOfRecommandation >= item.goalOfRecommandation" ><i class="el-icon-success"></i>已达标</span>
                            <span class="pop no-get" v-else><i class="el-icon-warning"></i>未达标</span>
                        </div>
                    </div>

                    <div class="cell-m"><div class="cell-item">
                            <span class="goal-text">月面试目标：
                                <i class="green" v-text="item.goalOfInterview">100</i>
                            </span>
                        </div>
                        <div class="cell-item">
                            <span class="goal-text">实际月面试：
                                <i class="green" v-if="item.realOfInterview >= item.goalOfInterview" v-text="item.realOfInterview">100</i>
                                <i class="orange" v-else v-text="item.realOfInterview">100</i>
                            </span>
                            <span class="pop get" v-if="item.realOfInterview >= item.goalOfInterview"><i class="el-icon-success"></i>已达标</span>
                            <span class="pop no-get" v-else><i class="el-icon-warning"></i>未达标</span>
                        </div>
                    </div>

                    <div class="cell-m"><div class="cell-item">
                            <span class="goal-text">月offers目标：
                                <i class="green" v-text="item.goalOfOffer">100</i>
                            </span>
                        </div>
                        <div class="cell-item">
                            <span class="goal-text">实际月offers：
                                <i class="green" v-if="item.realOfOffer >= item.goalOfOffer" v-text="item.realOfOffer">100</i>
                                <i class="orange" v-else v-text="item.realOfOffer">100</i>
                            </span>
                            <span class="pop get" v-if="item.realOfOffer >= item.goalOfOffer"><i class="el-icon-success"></i>已达标</span>
                            <span class="pop no-get" v-else><i class="el-icon-warning"></i>未达标</span>
                        </div>
                    </div>
                    <div class="cell-r">
                        <a href="javascript:;" 
                            class="edit-btn" 
                            v-if="canEdit(item.monthly)"
                            @click="editGoal(index)">
                            <!-- <svg class="icon edit-goal-list" aria-hidden="true">
                                <use xlink:href="#icon-edit" class="tx-size"></use>
                            </svg> -->
                            <img src="~@src/assets/images/program/edit_icon_1.png" alt="">
                            编辑
                        </a>
                    </div>
                </div>
            </div>
            <div class="empty-data" v-else>
                <span class="empty-img "></span>
                <p>暂无数据</p>
            </div>

            <div class="my-goal-footer" v-if="list.length>0">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="start"
                    :page-sizes="[10, 20, 30, 50]"
                    :page-size="5"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
            <add-program-goal
                v-if="isAddGoal"
                :goalParams="goalParams"
                @add-goal-finish="addGoalFinish"
            />
        </div>

        <!-- <div class="lbd-sign"></div> -->
    </div>
</template>

<script>
import moment from 'moment';
import projectDetailService from '#/js/service/projectDetailService.js';

import AddProgramGoal from './component/add-program-goal.vue';

export default {
    name: 'my-goal',
    components: {
        AddProgramGoal
    },
    data() {
        return {
            isAddGoal: false,
            start: 0,
            total: 0,
            take: 10,
            list: [],
            goalParams: {},
        };
    },
    mounted() {
        this.getMyGoalPage(true);
    },
    methods: {
        holdGoal() {
            this.goalParams =  { 
                    type: 'add', 
                    businessType: 'personal',
                };
            this.isAddGoal = true;
        },
        addGoalFinish() {
            this.isAddGoal = false;
            this.getMyGoalPage();
        },

        getMyGoalPage(isFirst) {
            const start = this.start > 0 ? this.start -1 : 0;
            let params = {
                start: start * this.take,
                take: this.take
            };
            const loading = this.$loading({
                lock: true,
                text: '页面加载中~',
                background: 'rgba(255, 255, 255, 0.7)'
            });
            projectDetailService.getMyGoalPage(params).then(res => {
                this.total = res.total;
                this.list = res.list;
            }).finally(() => {
                loading.close();
                if(!isFirst){
                    this.$nextTick(() => {
                        this.$refs.goalList && this.$refs.goalList.querySelector('.goal-list') && this.$refs.goalList.querySelector('.goal-list').scrollIntoView(true);
                    });
                }
            });
        },
        handleSizeChange(val) {
            this.start =1;
            this.take = val;
            this.getMyGoalPage();
        },
        handleCurrentChange(val) {
            this.start = val;
            this.getMyGoalPage();
        },

        // 判断是否可编辑
        canEdit(monthly) {
            const _now = moment().format('YYYY-MM');
            return moment(_now).isSameOrBefore(monthly);
        },
        // 编辑目录(当月才能编辑)
        editGoal(index) {
            this.goalParams =  { 
                    type: 'edit', 
                    businessType: 'personal',
                    ...this.list[index]
                };
            // this.$emit('eidt-goal', index);
            this.isAddGoal = true;
        },
    }
};
</script>

<style lang="scss" scope>
// @media screen and (min-width: 1400px) and (max-width: 1680px) {
//     .cell-l {
//         width: 300px !important;
//     }
//     .cell-m {
//         width: 290px !important;
//     }
// }
// @media screen and (max-width: 1400px) {
//     .cell-l {
//         width: 200px !important;
//     }
//     .cell-m {
//         width: 250px !important;
//     }
// }

.my-goal{
    >div,h1,span,p{
        font-family: 'Microsoft YaHei';
        box-sizing: border-box;
    }
    // width: 1200px;
    // margin: 0 auto;
    // min-height: calc(100vh - 148px);

    height: 100%;
    overflow-y: auto;
    padding: 20px;
    .my-goal-content {
        width: 1200px;
        margin: 0 auto;
        min-height: calc(100vh - 130px);
        background-color: #fff;
        border-radius: 8px;
        .goal-top{
            padding: 0 20px;
            height: 60px;
            line-height: 60px;
            width: 100%;
            border-bottom: 1px solid #eee;

            display: flex;
            justify-content: space-between;
            align-items: center;

            // >span{
            //     font-size: 16px;
            //     font-weight: 400;
            //     color: #333333;
            //     &::before{
            //         content: '';
            //         display: inline-block;
            //         width: 10px;
            //         height: 18px;
            //         background: #38BC9D;
            //         vertical-align: middle;
            //         margin-right: 10px;
            //     }
            // }

            .goal-top-title {
                font-size: 16px;
                color: #033333;
            }
            .hold-goal{
                // float: right;
                // margin-top: 15px;

                color: #fff;
                font-size: 14px;
                border-radius: 4px;
            }
        }
        .goal-list-wrap{
            min-height: calc(100vh - 274px);
            .goal-list{
                width: 100%;
                height: 93px;
                border-bottom: 1px solid #eee;
                vertical-align: top;
                font-size: 0;
                padding: 22px 0 20px;
                &:last-child{
                    border-bottom: 1px solid transparent;
                }
                >div{
                    display: inline-block;
                    vertical-align: top;
                }
                .cell-l{
                    // width: 274px;
                    width: 200px;
                    height: 50px;
                    // text-align: left;
                    text-align: center;
                    font-size: 0;
                    .empty{
                        height: 100%;
                        display: inline-block;
                        vertical-align: middle;
                    }
                    .break-wrap{
                        display: inline-block;
                        vertical-align: middle;
                        .handover{
                            display: inline-block;
                            font-size: 14px;
                            font-weight: 400;
                            color: #38BC9D;
                            line-height: 18px;
                            padding: 0 6px;
                            border: 1px solid #38BC9D;
                            border-radius: 9px;
                            box-sizing: border-box;
                            margin-bottom: 5px;
                        }
                        .date{
                            font-size: 14px;
                            font-weight: 400;
                            color: #333333;
                        }
                    }
                }
                .cell-m{
                    // width: 280px;
                    width: 300px;
                    .cell-item{
                        width: 100%;
                        height: 20px;
                        &:first-child{
                            margin-bottom: 10px;
                        }
                        .goal-text{
                            font-size: 14px;
                            font-weight: 400;
                            color: #333333;
                            >i{
                                font-style: normal;
                            }
                            .green{
                                color: #38BC9D;
                            }
                            .orange{
                                color: #FF6564;
                            }
                        }
                        .pop{
                            display: inline-block;
                            padding: 3px 8px 3px 4px;
                            border-radius: 10px;
                            font-size: 14px;
                            font-weight: 400;
                            margin-left: 10px;
                            >i{
                                margin: 0 4px;
                                width: 14px;
                                height: 14px;
                            }
                        }
                        .get{
                            color: #38BC9D;
                            background: #E0FAF4;
                        }
                        .no-get{
                            color: #FF6564;
                            background: #FDE4E4;
                        }
                    }
                }
                .cell-r{
                    width: 86px;
                    height: 47px;
                    line-height: 47px;
                    text-align: center;
                    .edit-btn{
                        display: none;
                        font-size: 14px;
                        font-weight: 400;
                        color: #38BC9D;
                        .edit-goal-list{
                            width: 16px;
                            height: 16px;
                            margin-right: 6px;
                        }
                        >img{
                            width: 16px;
                            height: 16px;
                            margin-right: 6px;
                            vertical-align: middle;
                        }
                    }
                }
                &:hover{
                    .cell-r{
                        .edit-btn{
                            display: inline-block;
                        }
                    }
                }
            }
        }
        .empty-data{
            text-align: center;
            padding-bottom: 100px;
            padding-top: 50px;
            .empty-img{
                display: inline-block;
                background: url("~@src/assets/images/home/icon_no_data.svg") no-repeat;
                background-size: contain;
                width: 160px;
                height: 160px;
            }
            >p{
                font-size: 16px;
                line-height: 21px;
                margin-bottom: 0px;
            }
        }
        .my-goal-footer{
            text-align: center;
            // margin: 39px auto;
            padding: 40px;
        }
    }

    .lbd-sign {
        padding-top: 20px;
        &:after {
            content: '© 2024 liebide.com';
            margin-left: 50%;
            text-align: center;
            font-size: 14px;
            color: #999;
        }
    }
}

</style>